import { InjectionToken } from '@angular/core';
import { LvWheelConfigs } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const LV_WHEEL_CONFIG: InjectionToken<LvWheelConfigs> = new InjectionToken<LvWheelConfigs>('lvWheelConfigs', {
  providedIn: 'root',
  factory: () => ({
    assetsUrl: 'https://lvbet-static.com/images/other/lv-wheel-v2/',
    assetsLanguage: 'en'
  })
});
